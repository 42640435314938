@import './guideVar.scss';

.Analytics {
    &__Container {
        width: 100%;
        //height: fit-content;
        height: 100%;
        min-width: 954px;
        max-width: 1044px;
        position: relative;  
        margin-left: auto;
        margin-right: auto;
        padding: 32px;
    }

    &__Download {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: fit-content;
        height: 100%;
        
        &__Btn {
            display: flex;
            width: fit-content;
            height: fit-content;
            align-items: center;
            padding: 11px 10px;
            justify-content: center;
            border-radius: 8px;
            border: 1px solid $color-gray-300;
            background: $color-white;
            cursor: default;

            &:hover {
                background: $color-gray-100;
            }
        }
    }

    &__Download {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: fit-content;
        height: 100%;
        
        &__Btn {
            display: flex;
            width: fit-content;
            height: fit-content;
            align-items: center;
            padding: 11px 10px;
            justify-content: center;
            border-radius: 8px;
            border: 1px solid $color-gray-300;
            background: $color-white;
            cursor: default;

            &:hover {
                background: $color-gray-100;
            }
        }
    }

    &__Contents {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: $color-white;
        border: 1px solid $color-gray-200;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: $spacing32;
    }

    &__BreakLine {
        width: 100%;
        height: 1px;
        background: $color-gray-300;
        border: 0;
        margin: 0;
    }

    &__Table {
        border-collapse: collapse;
        width: 100%;

        &__Wrap {
            width: 100%;
            height: fit-content;
            border-radius: 10px 10px 0 0;
            overflow: hidden;
        }

        &__Head {
            word-break: keep-all;
            padding: $spacing16 $spacing8;
            text-align: center;
            background: $color-prim-50;
            border-bottom: 2px solid $color-gray-300;

            &__gray {
                @extend .Analytics__Table__Head;
                background: $color-gray-100;
            }
        }

        &__Body {
            word-break: break-all;
            padding: $spacing24 $spacing8;
            text-align: center;

            &__underline {
                @extend .Analytics__Table__Body;
                border-bottom: 1px solid $color-gray-300;
            }
        }

        &__Detail {
            padding: $spacing16 $spacing8;
            text-align: left;
            background: $color-gray-100;
        }

    }

    &__Dimmed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }

    &__TopChart {
        &__PieWrap {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__InfoBox {
            width: 100%;
            background: $color-prim-150;
            border: 1px solid $color-prim-300;
            border-radius: 10px;
            display: flex;
            padding: $spacing16;
            align-items: center;
        }
    }
}