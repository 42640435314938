@import './guideVar.scss';

.DBLineGraphS {
    
    &__Container {
        display: flex;
        flex-direction: column;
    }

    &__TextWrap {
        display: flex;
        flex-direction: column;
        cursor: default;
    }

    &__Category {
        display: flex;
        align-items: center;
    }

    &__NoChartWrap {
        width: 100%;
        height: 182px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__ChartWrap {
        width: 100%;
        min-width: 0;
        //overflow: hidden;
        overflow: visible;
        display: block;
    }

    &__GraphWrap {
        display: flex;
        flex-direction: column;
    }

    &__XAxis {
        width: 100%;
        color: $color-gray-300;
        height: 1px;
        background-color: $color-gray-300;
        border-width: 0px;
        margin-block: 0;
    }

    &__Dates {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: default;
    }
}

.DBLineGraphL {

    &__Container {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    &__NoChartWrap {
        width: 100%;
        height: 335px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__Date {
        position: absolute;
        top: 313px;
        cursor: default;

        &__left {
            @extend .DBLineGraphL__Date;
            left: 53px;
        }

        &__right {
            @extend .DBLineGraphL__Date;
            right: 0px;
        }
    }
}

.DBBarGraphL {

    &__Container {
        display: flex;
        flex-direction: column;
    }

    &__NoChartWrap {
        width: 100%;
        height: 495px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
